.deliverable-file-upload {
  &__existing {
    display: none;
  }

  .core--button {
    margin-top: 0 !important;
  }

  &.has-file {
    .deliverable-file-upload__existing {
      display: block;
    }
    .project_deliverable_new_file_upload_id,
    .project_deliverable_file_upload_id {
      display: none;
    }
  }
  &.new-version {
    .project_deliverable_new_file_upload_id {
      display: block;
    }
  }
  &.selected-existing-file {
    .project_deliverable_new_file_upload_id {
      display: none;
    }
  }
  &.uploaded-new-file {
    &:not(.new-version) {
      .project_deliverable_file_category_id {
        display: block;
      }
    }
    .project_deliverable_file_upload_id {
      display: none;
    }
  }

}