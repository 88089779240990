.project-assign {
  position: fixed; 
  bottom: 0; left: 0; 
  width: 100%; 
  @apply bg-opacity-90 bg-brand-navy;
  @apply p-4;
  z-index: 1000;

  .container {
    @apply mx-auto;
    min-width: 1300px;
  }

  &-inner {
    @apply flex justify-center items-center;
    > div {
      margin: 0 10px;
    }
    select {
      padding: 4px 10px;
      border: 1px solid black;
      appearance: none;
    }
    label {
      margin: 0 5px;
      color: white;
      input {
        margin-right: 5px;
      }
    }
  }
  &-text {
    color: white;
  }
  &-input {
    @apply cursor-pointer;
    @apply block;
    @apply p-0;
    width: 20px;
    height: 20px;
    @apply border border-solid border-line;
    @apply rounded;
    @apply bg-white;
    @apply bg-no-repeat bg-center;
    @apply appearance-none;
    &:checked {
      @apply border-brand-blue;
      background-image: url('../../../../node_modules/@h2compliance/element-js/src/images/icons/tick--grey.svg');
    }
  }
}