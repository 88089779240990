
 @keyframes highlight {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}


.clipboard-pill {
  background: yellow;
  @apply inline-block;
  @apply rounded-xl;
  @apply bg-line;
  @apply py-1 px-3;
  @apply mr-1 mb-1;
  @apply whitespace-nowrap;
  span {
    @apply flex justify-between items-center;
  }
  i {

    background-position: 50% 50%;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;

    background-image: url('../../images/clipboard.svg');
  }
  &:hover {
    @apply bg-opacity-50 bg-line;
  }
  &.clicked {
    animation: highlight 0.5s both;
  }
  &.ticked {
    i {

      background-image: url('../../images/tick--grey.svg');
    }
  }
}
