/* Entry point for your PostCSS build */
@import "@h2compliance/element-js/dist/css/application.css";
@import 'actiontext.css';
@import 'components/*.css';
@import 'trix';


@tailwind base;
@tailwind components;
@tailwind utilities;

.h2-access {
  width: 210px;
  @apply flex justify-start items-center;
  @apply text-brand-white;
  @apply rounded-xl;
  @apply bg-brand-grey;
  @apply py-3 px-3;
  .icon {
    @apply mr-3;
  }
  &:hover {
    @apply bg-black;
  }
}

.tippy-list-item {
  @apply flex justify-between items-start;
  @apply py-2;
  @apply border-b border-solid border-line;
  font-size: 0.8rem;
  &:last-child {
    @apply border-b-0;
  }
  &.no-line {
    @apply border-none;
  } 
  a > span {
    @apply border-none;
  }

}

.dashboard--stats {
  @apply flex justify-between items-center;
  margin: 0 -10px 40px -10px;
  .core--section:first-child {
    flex: 1;
    margin: 0 10px;
  }
  .core--section:last-child {
    flex: 2;
    margin: 0 10px;
  }
}

.sds-codes {
  @apply flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.sds-code {
  @apply rounded-xl;
  @apply bg-transparent;
  @apply border border-solid border-line;
  @apply transition-all;
  @apply px-2 py-1;
  margin: 4px 8px 4px 0;
  @apply inline-block;
  @apply flex;
  @apply items-center;
  @apply justify-start;
  @apply cursor-default;

  .sds-comparison--critical & {
    @apply bg-white;
    @apply border-none;
  }

  &--extra {
    border: 1px solid #555;
  }

  i {
    width: 10px;
    height: 10px;
    @apply mr-1;
    @apply rounded-full;
  }

  &--priority__critical_priority i { @apply bg-brand-purple; } 
  &--priority__high_priority i { @apply bg-brand-red; } 
  &--priority__medium_priority i { @apply bg-brand-orange; } 
  &--priority__low_priority i { display: none; } 

  &--priority__ignore { display: none; i { display: none } }



  &.code-faded {
    opacity: 0.25;
  }
  &.code-highlighted {
    @apply bg-black;
    @apply border-black;
    color: white;
  }
}

.table-col-small-stat {
  width: 30px !important;
}

.core--table__heaer .table-col-small-stat {
  transform: rotate(45deg);
  text-align: right;
}

.sds-comparison {
  @apply mb-4;
  table {
    @apply w-full;
    @apply border-collapse;

    td, th {
      @apply p-4;
      @apply text-left;
      @apply bg-white;
      @apply border border-solid border-line;
      &.gap {
        @apply bg-transparent;
        @apply pl-0;
        width: 1px;
        border: none !important;
      }
    }

    tbody tr td:first-child {
      @apply font-bold;
    }

    .excel {
      background: transparent;
    }

    .match {
      width: 60px;
      @apply text-center;
      img {
        @apply inline-block;
      }
    }


  }
}


.sds-comparison--critical {
  @apply px-4 py-6;
  @apply bg-black;
  @apply flex;
  @apply justify-start;
  @apply items-center;
  @apply mb-4;

  &__label {
    @apply text-white;
    @apply text-sm;
    @apply pr-4;
    @apply border-r border-solid border-white/50;
    @apply mr-4;
  }

}

.core--table {
  .sds-icon {
    width: 40px;
    @apply text-center;
    .core--table-cell__content {
      @apply flex;
      @apply justify-center;
      @apply items-center;
    }
  }
}

.core--table__record-split {
  @apply flex justify-start items-center;
  span.split-primary {
    @apply truncate;
  }
  span.split-secondary {
    @apply pl-2;
    @apply ml-2;
    @apply border-l border-solid border-line;
    @apply text-opacity-50 text-black;
  }
}

.severity {
  width: 22px;
  height: 22px;
  display: block;
  @apply rounded-full;
  &.severity-x {
    display: none;
  }
  &.severity-0 {
    @apply bg-line;
  }
  &.severity-1 {
    @apply bg-brand-yellow;
  }
  &.severity-2 {
    @apply bg-brand-orange;
  }
  &.severity-3 {
    @apply bg-brand-red;
  }
  &.severity-4 {
    @apply bg-brand-purple;
  }
  
}

.discrepancy-list, .discrepancy-box {

  @apply p-6;
  @apply ml-4;
  @apply mb-4;
  @apply bg-white;

  &:last-child {
    @apply mb-0;
  }

  span.discrepancy-box-link  {
    text-decoration: underline;
    &.fade {
      color: rgba(0,0,0,0.3);
    }
  }

  &.black {
    @apply bg-black;
    @apply text-white;

    a span.discrepancy-box-link , span.discrepancy-box-link  {
      color: rgba(255,255,255,0.3);
    }
  }
  h3 {
    @apply font-bold;
    font-size: 16px;
  }

  h2 {
    @apply font-bold;
    font-size: 17px;

    &.has-value-change {
      @apply flex justify-between items-center;
    }
  }
}

.discrepancy-list {
  h2 {
    @apply mb-4;
  }
  li {
    @apply mb-3;
    @apply pb-3;
    border-bottom: 1px solid rgba(151,151,151,0.3);
    &:last-child {
      border-bottom: none;
      @apply mb-0;
    }
  }
}
.discrepancy-link {
  @apply flex justify-start items-start;
}

.core--link.subrow.arrow::after {
  display: none !important;
}

.core--search-sidebar {
  overflow-y: scroll;
}

.core--search-bucket-divider {
  @apply px-2;
  @apply my-2;
  &__line {
    @apply border-none;
    @apply border-opacity-20 border-t border-solid border-white;
  }
  &__label {
    @apply px-2;
    @apply mt-4;
    @apply text-white;
  }
  &__description {
    @apply mt-2;
    @apply text-opacity-50 text-white;
  }
}

.sds--value-change {
  @apply flex justify-start items-center;
  @apply text-sm;
  @apply ml-2;
  text-decoration: none !important;

  span {
    text-decoration: none;
    @apply ml-1;
    opacity: 0.5;
  }

}

.amazon_web_mapping_statement_id .options {
  display: block !important;
}