.plus--info-bar {
  @apply flex justify-between items-center;
  @apply relative;
  @apply flex-1;
  @apply py-2;


  &__title {
    min-width: 100px;
    font-size: 10px;
    @apply text-brand-grey;
    text-transform: uppercase;
    white-space: nowrap;
    margin-right: 10px;
    &--longer {
      width: 200px;
    }
  }
  &__bar {
    flex: 1;
    height: 16px;
    border-radius: 12.5px;
    @apply border border-solid border-line;
    @apply bg-line;
    position: relative;
    overflow: hidden;
  }


  &__stat {
    height: 100%;
    position: absolute;
    top: 0;
  }

  [data-controller="popup"] {
    @apply contents;
  }


  .file-upload-pending { @apply bg-white; }
  .file-upload-rejected { @apply bg-brand-red; }
  .file-upload-completed { @apply bg-brand-green; }
  .project-deliverable-pending { @apply bg-white; }
  .project-deliverable-upload-rejected { @apply bg-brand-red; }
  .project-deliverable-awaiting_qa { @apply bg-brand-blue; }
  .project-deliverable-awaiting_review { @apply bg-brand-purple; }
  .project-deliverable-completed { @apply bg-brand-green; }
  
}

.core--table-row {
  .plus--info-bar__title {
    display: none;
  }
}