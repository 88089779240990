.sds--progress-bar {
  @apply border border-solid border-line;
  border-radius: 12.5px;
  @apply relative;
  @apply w-full;
  @apply bg-white;

  &_cumulative {
    width: 1px;
    height: 30px;
    @apply absolute;
    top: 0px;
    &:after {
      bottom: -10px;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(#876AF6, 0);
      border-bottom-color: #876AF6;
      border-width: 10px;
      margin-left: -10px;
    }
  }
  &_progress {
    height: 30px;
    @apply bg-brand-green;
    border-radius: 12.5px;
  }
}

.sds--progress-table {
  @apply w-full;
  @apply border-collapse;
  th, td {
    @apply text-right;
    @apply align-top;
    @apply p-2;
  }
}