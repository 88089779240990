.sds--deliverable-progress {
  @apply w-full;
  &__wrapper {
    @apply rounded-xl;
    @apply shadow-md;
    @apply bg-brand-white;
    @apply border border-solid border-line;
    @apply overflow-hidden;
    @apply flex;
    @apply justify-between;
  }

  &__bar {
    height: 20px;

    &.awaiting_qa {
      @apply bg-brand-blue;
    }
    &.awaiting_review {
      @apply bg-brand-purple;
    }
    &.completed {
      @apply bg-brand-green;

    }
    &.pending {
      @apply bg-brand-white;

    }
    &.rejected {
      @apply bg-brand-red;
    }
  }

}